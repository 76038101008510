import $ from 'jquery';
import React from 'react';
import UserToken,{checkUserSession} from '../function_lib/userToken';
import {AjaxCallMethod,getAccessRolesNew,RespBkMthdMapp,_getInitParam,defaultLogo
,CallMapMethod_CBack} from '../function_lib/common_lib';
//import {CallCMSMapMethod,AjaxCallMethodCMS} from '../function_lib/common_cms';
import {Add_Network_Comp} from '../components/add_network_comp';

import { Player } from 'video-react';

import GooglePlay from '../assets/images/google-play.jpg';
import AppStore from '../assets/images/mac.jpg';
import './index.css';
import './login_v2.css';


let loginUser="";
let userObject="";
let _indInitObject={};
let LogoImg;
let clsIndexLogin;
class Index extends React.Component{
  constructor(props){
    super(props);
    _indInitObject=_getInitParam();
    checkUserSession(false);//for limittime
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      userObject= UserToken.getUserObject();
      loginUser=userObject[0].person_name;
      window.location.href="./Dashboard";
    }
    LogoImg=defaultLogo();
    clsIndexLogin=this;
  }
  checkNetwork(){
    let map=[];
        map.push({name:"curl_type",value:"getNetworkd"});
        map.push({name:"res_func",value:"callBackrrr"});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getNetworkd";
        CallMapMethod_CBack(this,map,function(data,msg){
          //alert(data);
          let lsNetW=JSON.parse(data);
          if(lsNetW.length===0){
            $("#divNetWorkComp").modal('show');
          }

        })
  }

  checkLogin(){
    
    let map=[];
    map.push({name:"curl_type",value:"logint"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"p1",value:$("#p1").val()});
    map.push({name:"p2",value:$("#p2").val()});
    map["res_func"]="callBackrrr";
    map["curl_type"]="logint";
    CallMapMethod_CBack(clsIndexLogin,map,function(data,msg){
      //alert(data);
      if(data!=="0" && data!==""){
        //alert(data);
        let jsData=JSON.parse(data);
        //alert(jsData.user_info.length);
        if(jsData.user_info.length===1){
          let datat={};
          datat["user"]=[{"id":jsData.user_info[0]["id"],
            "app_user_id":jsData.user_info[0]["parent_id"],
            "first_name":jsData.user_info[0]["contact_person"],
            "last_name":"",
            "type":"1"
          }];
          //alert(jsData.user_info[0]["authority"]);
          //datat["role"]=[{"col1":"user,master"},{"col2":btoa(jsData.user_info[0]["access_location"])}];
            datat["role"]=[{"col1":btoa(jsData.user_info[0]["authority"])},{"col2":btoa(jsData.user_info[0]["access_location"])}];
          
          clsIndexLogin.okSucc(JSON.stringify(datat),"success");
        }else{
          clsIndexLogin.okSucc("0","");
        }
      }
      
    })
      
  


//     if($("#p1").val()==="admin" && $("#p2").val()==="1001"){
//       let datat={};
//       datat["user"]=[{"id":"nBwJY07WZF7E0ew-8Pd0966rdtKfpKPorGMq2OOrZqQG0LimGvQzs2K27TOOnPHB3hvoha5jqygNtyGMeUYycw",
//       "app_user_id":"nBwJY07WZF7E0ew-8Pd0966rdtKfpKPorGMq2OOrZqQG0LimGvQzs2K27TOOnPHB3hvoha5jqygNtyGMeUYycw",
//     "first_name":"Admin",
//   "last_name":"Admin",
// "type":"1"}];
// datat["role"]=[{"col1":"user,master"}];
//       this.okSucc(JSON.stringify(datat),"success");
//     }else{
//       this.okSucc("0","");
//     }
  }

  okSucc(data,datares){
    //alert(data+datares);
   // return;
   if(data===-10){
    return;
   }
    if(data!="0" && data!=""){
      $("#getReponse").html("Login Successfully..");
     checkUserSession(true);
     localStorage.setItem('userData',data);
     
     window.location.href="./dashboard";
     
     
    }else{
      $(".respSSL").html("Invalid UserName or Password !");
    }

}

okFirstSetupResp(data,msg){
alert(data+msg)
}
componentDidMount(){
  AjaxCallMethod(this,"okSuccCNOT");
  this.checkNetwork();
  //RespBkMthdMapp(this,"","keyEvent");
}
    render(){
        return(<div class="login-form-v2">
          <div class="container">
              <div class="row g-0  rounded">
                  {/* <!-- Image Section --> */}
                  <div class="col-md-6 d-none d-md-block  cover-lefft-side">
                      {/* <img src="https://onetreeplanted.org/cdn/shop/articles/nature_facts_1600x.jpg?v=1705008496"/> */}
                      {/* <img src={_indInitObject["logoPath"].trim()!==""?_indInitObject["logoPath"]:LogoImg} style={{"width":"100%"}} className="img-fluid" /> */}
                  </div>
                  {/* <!-- Form Section --> */}
                  <div class="col-md-6 d-flex align-items-center">
                  
                      <div class="w-100 loginForm-log-v2">
                      <img src={_indInitObject["logoPath"].trim()!==""?_indInitObject["logoPath"]:LogoImg} style={{"width":"100%"}} className="img-fluid d-mobile" />
                         <hr class="d-mobile"/>
                          <h3 class="mb-3">Log in using your registered Detail</h3>
                          <small class="text-muted mb-4 d-block"> </small>
                          <form id="frmLogin" name="frmLogin" action="#">
                          <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                    <input type="hidden" name="curl_type" id="curl_type" value="logint" />
                    <input type="hidden" name="m_type" id="m_type" value="logint" />

                              <div class="mb-3">
                                  <input type="text" class="form-control required" id="p1" name="p1"  placeholder="UserName.."/>
                              </div>
                              <div class="mb-3">
                                  <input type="password" rf="#btnLogin" id="p2" name="p2" class="form-control required keyEvent_btn" placeholder="Password"/>
                              </div>
                              <button type="button"  onClick={()=>this.checkLogin()} class="btn _btnSetColor123 btn-sm float-right w-100 btn-success">Log in</button>
                          </form>
                          <div class="login mt-3 text-center">
                              <p>forget password? <a href="#">click here</a></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <Add_Network_Comp  />
          </div>);
    }
}
export default Index