import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
// import {Comp_Add_Product_Master} from '../components/comp_add_product_master';
// import {CompItem_Auto_Complete_New} from '../components/comp_auto_complete';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';
//import Comp_Read_Study_Material from '../lms_component/comp_read_study_material';
import Comp_View_Pdf from '../lms_component/comp_view_pdf';

let loginUser="";
let userObject="";
let companyName="";
let clsProductMaster;

let rf="0";
let _objAccess={};
class View_Users_Log extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={ listUsers:[],stEditProduct:{},
                  stListUnit:[],stListItemCategory:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsProductMaster=this;
      }

      getProUnitnCatMaster(){
        let map=[];
        map.push({name:"curl_type",value:"getCatUnitMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
       
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCatUnitMaster";
        CallMapMethod_CBack(clsProductMaster,map,function(data,msg){
          //alert(data);
          if(data!=="0" && data!==""){
            let jsObject=JSON.parse(data);
            clsProductMaster.setState({stListUnit:jsObject["ls_unit"],
            stListItemCategory:jsObject["ls_item_cat"]},()=>{
            });
  
          }
          
        })

      }

      getRandomDateTimes(count, startDate, endDate) {
        let dates = [];
        let start = new Date(startDate).getTime();
        let end = new Date(endDate).getTime();
    
        for (let i = 0; i < count; i++) {
            let randomTime = Math.floor(Math.random() * (end - start) + start);
            dates.push(new Date(randomTime));
        }
    
        // Sort dates in increasing order
        dates.sort((a, b) => a - b);
         return dates[0];
        //return dates.map(date => date.toISOString()); // Returns dates in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ)
    }
     getRandomDateTime(startDate, endDate) {
        let start = new Date(startDate).getTime();
        let end = new Date(endDate).getTime();
        
        let randomTime = Math.floor(Math.random() * (end - start) + start);
        return new Date(randomTime);
    }

      getRandomDates(count, startDate, endDate) {
        let dates = [];
        let start = new Date(startDate).getTime();
        let end = new Date(endDate).getTime();
    
        for (let i = 0; i < count; i++) {
            let randomTime = Math.floor(Math.random() * (end - start) + start);
            dates.push(new Date(randomTime));
        }
    
        // Sort dates in increasing order
        //dates.sort((a, b) => a - b);
    
        return dates[0];
    }

      getStudyMasterial(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getStudyMasterial"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        //map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getStudyMasterial";
        CallMapMethod_CBack(clsProductMaster,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsProductMaster.setState({listUsers:listData},()=>{
                  //window.getPagination("#tbData");
                  clsProductMaster.forceUpdateHandler();
              
                });
              }
        });
      }
      okRespProductAdd(data,msg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful",msg,true);
          //clsProductMaster.getProductMaster();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",msg,false);
        }
        // alert(data);
      }


 setEditProductMaster(item){
  clsProductMaster.setState({stEditProduct:item});
 }
 onViewPdf(data){
  //alert(JSON.stringify(data));
  clsProductMaster.setState({stItemProduct:data});
}              
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
//this.getProUnitnCatMaster();
this.getStudyMasterial();

$('#divFeeCategory').on('hidden.bs.modal', function () {

  clsProductMaster.getStudyMasterial();
 
});
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  {/* <CompItem_Auto_Complete_New setData={true} autoList={this.state.listUsers}/> */}
<div className="row content12 mt-2 mb-2 float-right11">


<div className='col-lg-4 col-md-4 col-sm-4 float-right11'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right11">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getStudyMasterial()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()}  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Users Log</b></h3>
                
                {/* <a className="btn btn-sm btn-danger float-right" href={"javascript:void(0)"}>Add New</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Member-Id</th>
                <th>Activity</th>
                <th>Timestamp</th>
                <th>IP Address</th>
                <th>Device</th>
                <th>Request</th>
                <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                    let request_state = ["Authorized", "Unauthorized", "Spamming"];
                    let strReqState=request_state[Math.floor(Math.random() * request_state.length)];

                    let member_id = ["ME-9082930"+index, "ME-908293333"+index, "ME-34589098"+index,"ME-93299883"+index,"ME-93299883"+index];
                    let strMember=member_id[Math.floor(Math.random()*member_id.length)];

                    let ossIds=["Windows","Mac","Android","iPhone","Ubuntu","Cent-OS"];
                    let strOss=ossIds[Math.floor(Math.random()*ossIds.length)];

                    let activityState=["Login","Study","Tasks","Chapter Reading","Logout"];
                    let strActivity=activityState[Math.floor(Math.random()*activityState.length)];

                    //let randomDates = getRandomDates(5, "2025-01-01", "2025-12-31");
                    //let randomDateTimes = this.getRandomDateTimes(5, "2025-01-01T00:00:00", "2025-12-31T23:59:59");
                    let randomDateTime = this.getRandomDateTime("2025-01-01T00:00:00", "2025-02-22T23:59:59");
                       return(<tr>
                    
                <td>{index+1}</td>
                <td>{strMember}</td>
                <td>{strActivity}</td>
                <td>{randomDateTime.toISOString()}</td>
                <td>222.129.234.234</td>
                <td>{strOss}</td>
                <td>
                    {strReqState==="Authorized"?<span class="badge bg-primary dash_rejected_claim1">{strReqState}</span>:''}
                    {strReqState==="Unauthorized"?<span class="badge bg-danger dash_rejected_claim1">{strReqState}</span>:''}
                    {strReqState==="Spamming"?<span class="badge badge-warning dash_rejected_claim1">{strReqState}</span>:''}
                    
                    
                </td>
            
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Activity
                    </button>
                    <div class="dropdown-menu">
                    {typeof _objAccess.can_edit!=="undefined"?<a class="dropdown-item" onClick={()=>this.setEditProductMaster(item)} data-bs-toggle="modal" data-bs-target="#divAddProductMaster" data-toggle="modal" data-target="#divAddProductMaster" href="javascript:void(0)">View/Edit</a>:''} 
                     {/* <a class="dropdown-item" onClick={()=>this.getBillPrint(item)} data-bs-toggle="modal" data-bs-target="#divPrintInvoice" data-toggle="modal"  data-target="#divPrintInvoice" href="javascript:void(0)">Print</a> */}
                     <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#divReadMaterial" data-toggle="modal"  data-target="#divPrintInvoice" href="javascript:void(0)">Track By User</a>
                     <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#divReadMaterial" data-toggle="modal"  data-target="#divPrintInvoice" href="javascript:void(0)">Track By IP</a>
                     <a class="dropdown-item"  data-bs-toggle="modal" data-bs-target="#divReadMaterial" data-toggle="modal"  data-target="#divPrintInvoice" href="javascript:void(0)">Track By System</a>
                    </div>
                  </div>
                    </td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
{/* <Alert_Content/> */}
<Footer/>
<Comp_View_Pdf proItem={this.state.stItemProduct} />
{/* <Comp_Read_Study_Material pdfSource={"https://lmstestd.bhandarkarpublications.com/dtup/A1.1.pdf"}/> */}
{/* <Comp_Add_Product_Master editProduct={this.state.stEditProduct} logUser={userObject} lsUnit={this.state.stListUnit} lsProCategory={this.state.stListItemCategory}/> */}

    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default View_Users_Log;