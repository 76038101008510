import $, { extend } from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,
  TableDatePicker,setKeyUpMapp,setKeyUpMapp_CBack,isCheckFloat} from '../function_lib/common_lib';
import {tmpAutoList} from '../function_lib/city_india';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import listData from '../function_lib/customeData';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';
import Comp_View_Payment_Breakup from '../components/comp_view_payment_breakup';
import Comp_View_Invoice_Breakup from '../components/comp_view_invoice_breakup';
import Pay_Old_Dues from '../components/pay_old_dues';
import {Comp_Auto_Complete,CompItem_Auto_Complete} from '../components/comp_auto_complete';
import Comp_Add_Blasting_Item from '../components/comp_add_blasting_item';
import {Link} from 'react-router-dom';
//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';
//import 'select2/dist/js/select2.full.min.js';
//import 'select2/dist/css/select2.min.css';

let loginUser="";
let userObject="";
let companyName="";
let clsCreateBilling;

let rf="0";
class Create_Blasting extends React.Component{
  // some imp lib assoc
  
  //end of imp lib assoc

  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListClassFee:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stListAccLedger:[],stListStudent:[],
      stStudentInvoice:[],stStuFeeSubmitted:[],stStuOldDues:[],
    stStuPaymentBreakdown:[],stStuInvoiceBreakdown:[],stStudentId:0,
  stListParty:[],stListProduct:[]};

      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsCreateBilling=this;
      }
     


      
  okSuccEntryLedg(data,smg){
        if(data==="001"){
          alert("Record Saved Successfully");
          window.location.reload();
          //Custom_Alert("Successful","Record Added Successfully",true);
          //clsCategoryMaster.getSessionYear();
          //$("#frmCreate").find("input[type=text]").val("");
        }else{
          alert(smg);
          //Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }


      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
//this.getMasters();

// product_add
this.clsBtnBreakUpsClick =new listData("_btnFeeBreakdown", "hellow");
this.clsBtnBreakUpsClick.canEdit=false;
this.clsBtnBreakUpsClick.setOnChangeEvent("fee_breakdown",this.clsBtnBreakUpsClick);
this.clsBtnBreakUpsClick.formFormuals=[{"cal_fields":"bags,package","calc_sign":"*","output":"quantity"},
  {"cal_fields":"quantity,rate","calc_sign":"*","output":"amount"}
  // {"cal_fields":"amount,package","calc_sign":"+","output":"net_amount"}
];
this.clsBtnBreakUpsClick.preEventSetData=function setTotalAmount(){
  let totalAmount=0;
  for(let i=0;i<clsCreateBilling.clsBtnBreakUpsClick.purchaseItem.tt.length;i++){
    totalAmount+=parseFloat(clsCreateBilling.clsBtnBreakUpsClick.purchaseItem.tt[i]["amount"]);
  }

  
  document.getElementById("inv_amount").value=totalAmount;
  clsCreateBilling.calculateDiscNetAmount("inv_discount_per");
}

//payment breakdown
this.clsBtnPaymentBreadownClick=new listData("_btnPaymentBreakdown","hellow");
this.clsBtnPaymentBreadownClick.preEventSetData=function setPaymentTotal(){
}



setKeyUpMapp_CBack(this,"_keyupcustom",function cBack(event){
  //alert("i m in keyup");
  let getTagTargetValue=event.target.getAttribute("tag_jobject");
  let objTargetVal=JSON.parse(getTagTargetValue);
  let objInput=event.target;

  
  //alert(JSON.stringify(objTargetVal));
  if(objTargetVal["t_type"]==="discount"){
    let objSource=document.getElementById(objTargetVal["t_source"]);
    let objPerValue=document.getElementById(objTargetVal["t_per_val"]);
    let objResult=document.getElementById(objTargetVal["t_result"]);

    if(objInput.value.trim()===""){
      objInput.value="0";
    }

    //alert( isCheckFloat(objInput.value));
    if(isCheckFloat(objSource.value) && isCheckFloat(objInput.value)){
      objPerValue.value = (objSource.value * objInput.value / 100).toFixed(2);
      objResult.value=objSource.value-objPerValue.value;
    }
  }
})

}


calculateDiscNetAmount(eventObjId){
  let discountPer=0;
  let discountAmt=0;
 
  if(document.getElementById(eventObjId)){
    //alert("i m in object");
    let eventObject=document.getElementById(eventObjId);
    let getTagTargetValue=eventObject.getAttribute("tag_jobject");
    //alert(getTagTargetValue);
    let objTargetVal=JSON.parse(getTagTargetValue);
    let objInput=eventObject;
  
    
    //alert(JSON.stringify(objTargetVal));
    if(objTargetVal["t_type"]==="discount"){
      let objSource=document.getElementById(objTargetVal["t_source"]);
      let objPerValue=document.getElementById(objTargetVal["t_per_val"]);
      let objResult=document.getElementById(objTargetVal["t_result"]);
  
      //alert(objPer.value);
      if(objInput.value.trim()===""){
        objInput.value="0";
      }
      
      if(isCheckFloat(objSource.value) && isCheckFloat(objInput.value)){
        objPerValue.value = (objSource.value * objInput.value / 100).toFixed(2);
        objResult.value=objSource.value-objPerValue.value;
  
      }
    }
  }
 }

oldDues_okSucc(data,msg){
  if(data==="001"){
    clsCreateBilling.getStudentFeenDues();
    alert("Old Due Submitted Successfully");
  }
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-1">
  
<div className="row">
    
      <div className="col-md-12 col-sm-12">
         
      <div class="card card-secondary">
              {/* <!-- /.card-header --> */}
           <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>{"Create Blasting"}</b></h3>
                <Link className="btn btn-sm btn-danger float-right" to={"../view_blasting"}>{"View Blasting"}</Link>
                {/* <a className="btn btn-sm btn-danger float-right" href="../view_blasting">View Blasting</a> */}
              </div>
            {/* form data  */}
            <form class="form-group" id="frmFeeSubmission" name="frmFeeSubmission" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccEntryLedg")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("ledger_fuel_out")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("ledger_fuel_out")} />
                <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                <input type="hidden" name="invoice_ledger_id" id="invoice_ledger_id" value={btoa("7")}/>
                <input type="hidden" name="acc_inv_tran" id="acc_inv_tran" value={"1"}/>
            <div class="card-body">           
            <div className="row">
            
            <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="date" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Customer Name</label>
                                            <div className="input-group input-group-sm">
                                            <Comp_Auto_Complete comp_id={"ser_party_info"} comp_name={"ser_party_info"} target_obj={"party_info_id"} autoList={[]} placeHolder={"Customer Name"} propClass={"form-control form-control-sm required"} />
                                              <input type="hidden" id="party_info_id" name="party_info_id" value=""/>
                                            </div>
                                        </div>
                                  </div>
            <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Contact No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="contact_no" id="contact_no" class="form-control"/>
                                            </div>
                                        </div>
                                  </div>
                                  {/* <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Ref No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" id="ref_no" name="ref_no" class="form-control"/>
                                            </div>
                                        </div>
                                  </div> */}
                                  

                                 
                                  {/* <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Invoice No.</label>
                                            <div className="input-group input-group-sm">
                                            <input className="form-control required" type="number" id="invoice_no" name="invoice_no"/>
                                            </div>
                                        </div>
                                  </div> */}

            </div>
{/* end of first row */}
{/* table fee Breakdown */}
<div class="row">
<table class="table table-bordered table-sm">
                                        <thead>
                                            <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                <th>Item</th>
                                                <th>Unit</th>
                                                <th>No of Holes</th>
                                                <th>Size/Hole</th>
                                                <th>Total Size</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                                {/* <th>Net Amount</th> */}
                                                <th>Person Name</th>
                                                <th>Mob. No.</th>
                                                <th style={{"width":"40px"}}><a type="button" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#divAddBlastingItem" data-target="#divAddBlastingItem" data-toggle="modal" class="btn btn-sm btn-danger float-right" id="popAddItem" value="add">Add</a></th>
                                            </tr>
                                          
                                        </thead>
                                        <tbody id="tbItemList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_ItemList" name="p_ItemList" value="" />
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                    </div>
                                    <div class="col-md-10">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr style={{"background-color":"#FC766AFF"}}>
                                                    <th>Amount</th>
                                                    <th>Invoice Discount(%)</th>
                                                    <th>Discount Amount</th>
                                                    <th>Invoice Amount</th>
                                                    {/* <th>Amount Received</th> */}
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm"><input id="inv_amount" readOnly={"true"} rfnode="value" name="inv_amount" type="number" class="form-control required" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="inv_discount_per" rfnode="value" name="inv_discount_per" defaultValue={"0"} tag_jobject={"{\"t_type\":\"discount\",\"t_source\":\"inv_amount\",\"t_per_val\":\"inv_discount\",\"t_result\":\"inv_net_amount\"}"} type="number" class="form-control _keyupcustom required" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="inv_discount" readOnly={"true"} rfnode="value" name="inv_discount" type="number" class="form-control required" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="inv_net_amount" readOnly={"true"} rfnode="value" name="inv_net_amount" type="text" class="form-control required" /></div></td>
                                                    {/* <td><div class="input-group input-group-sm"><input id="inv_amount_received" rfnode="value" name="inv_amount_received" type="text" class="form-control" /></div></td> */}
                                                </tr>
                                            </thead>
                                        
                                        </table>
                                        
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-5">
                                    </div>
                                    <div class="col-md-7">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                    <th>Payment Mode</th>
                                                    <th>Transaction Amount</th>
                                                    <th>Narration</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm">
                                                      <select id="payment_mode" name="payment_mode" className="form-control piPaymentMember miPaymentMember">
                                                        <option value="0">-Payment Mode-</option>
                                                        <option value={"Cash"}>Cash</option>
                                                        <option value={"Card"}>Card</option>
                                                        <option value={"Cheque"}>Cheque</option>
                                                        <option value={"Netbanking"}>Netbanking</option>
                                                        <option value={"upi"}>UPI</option>
                                                      </select>
                                                      </div></td>
                                                    <td><div class="input-group input-group-sm"><input id="pay_value" name="pay_value" type="text" class="form-control piPaymentMember miPaymentMember" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="pay_narration" name="pay_narration" type="text" class="form-control piPaymentMember miPaymentMember" /></div></td>
                                                    <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miPaymentMember" cldname="piPaymentMember" cldhidname="piHidPaymentMember" resValue="p_paymentBreakdown" resname="tbPaymentBreakdown" id="_btnPaymentBreakdown" value="add" /></td>
                                                </tr>
                                            </thead>
                                            <tbody id="tbPaymentBreakdown">
                                            </tbody>
                                        </table>
                                        <input type="hidden" id="p_paymentBreakdown" name="p_paymentBreakdown" value="" />
                                        
                                    </div>
                                </div>

            </div>
            <div class="row">
                <div class="col-9"></div>
                    <div class="col-3 float-right">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" class="btn btn-sm btn-danger _btnSetColor">Save</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
            </form>
            {/* end of form data */}
                
              {/* <!-- /.card-body --> */}
            </div>
      </div>
    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Add_Blasting_Item logUser={userObject} editPopup={{}}/>
<Comp_View_Payment_Breakup paymentBreakdown={this.state.stStuPaymentBreakdown}/>
<Comp_View_Invoice_Breakup invoiceBreakdown={this.state.stStuInvoiceBreakdown} />
<Pay_Old_Dues studentId={this.state.stStudentId} stuOldDues={this.state.stStuOldDues}/>
    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Create_Blasting;