// Create a new file called context.js

import React, { createContext, useState } from 'react';

// Create a context object
export const GlobalContext = createContext();

// Create a provider component to wrap your entire app
export const GlobalProvider = ({ children }) => {
  // Define your global state variables
  const [gbStPartyInfo, setGbStPartyInfo] = useState(null);
  const [gbStItemInfo,setGbStItemInfo]=useState([]);
  const [gbStSiteMaster,setGbSiteMaster]=useState(null);
  const [gbStVehicleMaster,setGbVehicleMaster]=useState(null);

  const [gbStInventoryLedger,setGbInventoryLedger]=useState(null);
  const [gbStEmployee,setGbStEmployee]=useState(null);

  

  // Create functions to modify the global state
  const setEmployeeMaster=(listEmployeeMaster)=>{
    setGbStEmployee(listEmployeeMaster);
  }
  const setInventoryLedger=(listInventoryLedger)=>{
    setGbInventoryLedger(listInventoryLedger);
  }
  const setSiteMaster=(listSiteMaster)=>{
    setGbSiteMaster(listSiteMaster);
  }
  const setVehicleMaster=(listVehicleMaster)=>{
    setGbVehicleMaster(listVehicleMaster);
  }
  const setPartyInfo = (listPartyInfo) => {
    setGbStPartyInfo(listPartyInfo);
  }

  const setItemInfo=(listItemInfo)=>{
    //alert(JSON.stringify(listItemInfo));
    setGbStItemInfo(listItemInfo);
  }
  const getAdmins=()=>{
    return gbStItemInfo;
  }


  // Provide the state and functions to the entire app through the context provider
  return (
    <GlobalContext.Provider value={{ gbStPartyInfo, setPartyInfo, 
    gbStItemInfo, setItemInfo,
    gbStSiteMaster,setSiteMaster,
    gbStVehicleMaster, setVehicleMaster,
    gbStInventoryLedger,setInventoryLedger,
    gbStEmployee,setEmployeeMaster }}>
      {children}
    </GlobalContext.Provider>
  );
};