import React from 'react';


export const Comp_View_Pdf=(props)=>{
     //alert(JSON.stringify(props));

    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divPdfView" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">{(typeof props.proItem!=="undefined" && typeof props.proItem.product_name!=="undefined")?props.proItem.product_name:''}</h5>
              <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <form id="frmPdfView" name="frmPdfView">
           
            <div className="modal-body">
            <div className="row">
                      {/* <!-- left column --> */}
                      <div className="col-md-12">
                          <div className="card card-danger">
                                   <div className="card-body">
                                    {(typeof props.proItem!=="undefined") && (props.proItem.file_path!=="") ?
                                    <embed id="samp_comp_pa" width="100%" height="500px" 
                                    src={"https://muitestnond.ashtechnologies.in/controller/test_reader.php?pm=demReadBookMUI&rf="+btoa(props.proItem.id)+"&rd_index="+props.proItem.product_code} toolbar="0"></embed>
                                    :<div>No file Uploaded</div>
                                    // <embed id="samp_comp_pa" width="100%" height="500px" src={"https://muitestnond.ashtechnologies.in/controller/test_reader.php?pm=demReadBookMUI&=Fzy_Nrmonyl4uTUCnhOvPT6-yFC1jUXP0wPvnWXm3eBt2M22bwA0O6Gyk2JrXgva282LTyOKlpVIbN_4dKccxg"} toolbar="0">
                                    // </embed>
                
                }
                                   
                                    
                                      <div className="col-md-6 respSSL"></div>
                                      <div id="testing2dd2222"></div>
                                  </div>
                              
                          </div>
                      </div>
                  </div>
            </div>
            <div className="modal-footer">
            <div id="getReponse" style={{color:"red"}}></div>
             
            </div>
            </form>
          </div>
          </div>
          </div>
      );
    
}
export default Comp_View_Pdf;
