import React from 'react';
export const Comp_Quick_Activity=(props)=>{
return(<div class="card">
    <div class="card-header">
      <h5 class="card-title">Quick Access</h5>

      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fa fa-minus"></i>
        </button>
        <div class="btn-group">
          <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-wrench"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" role="menu">
            <a href="#" class="dropdown-item">Action</a>
            <a href="#" class="dropdown-item">Another action</a>
            <a href="#" class="dropdown-item">Something else here</a>
            <a class="dropdown-divider"></a>
            <a href="#" class="dropdown-item">Separated link</a>
          </div>
        </div>
        <button type="button" class="btn btn-tool" data-card-widget="remove">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    
    <div class="card-body">
    {/* <!-- Info boxes --> */}
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <a href={"../category_function"}>
            <div class="info-box">
              <span class="info-box-icon bg-info elevation-1"><i class="fa fa-cog"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Course Categories</span>
                <span class="info-box-number">
                  10
                  <small>%</small>
                </span>
              </div>
             </div>
             </a>
          </div>
          

          <div class="col-12 col-sm-6 col-md-3">
            <a href={"../addstudymaterial"}>
            <div class="info-box mb-3">
              <span class="info-box-icon bg-danger elevation-1"><i class="fa fa-book"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Study Materials</span>
                <span class="info-box-number">1,412</span>
              </div>
            </div>
            </a>
          </div>
         

          
          {/* <div class="clearfix hidden-md-up"></div> */}

          <div class="col-12 col-sm-6 col-md-3">
            <a href={"../addstudymaterial"}>
            <div class="info-box mb-3">
              <span class="info-box-icon bg-success elevation-1"><i class="fa fa-users white"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Technical Books</span>
                <span class="info-box-number">760</span>
              </div>
            </div>
            </a>
          </div>
          
          <div class="col-12 col-sm-6 col-md-3">
            <a href={"../addstudymaterial"}>
            <div class="info-box mb-3">
              <span class="info-box-icon bg-warning elevation-1"><i class="fa fa-braille"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Non-Technical Books</span>
                <span class="info-box-number">2,000</span>
              </div>
            </div>
            </a>
          </div>

        </div>
{/* end of quic activity */}

      
    </div>
    
    {/* <div class="card-footer">
      <div class="row">
      </div>
      
    </div> */}
    
  </div>)
}

export default Comp_Quick_Activity;