import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser_CBack,RespBkMthdMapp,detectEmojisAndSpecialCharacters,CallMapMethod_CBack} from '../function_lib/common_lib';
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


//import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import pdfIcon from '../assets/images/pdf_icon.png';
import mp3Icon from '../assets/images/mp3_icon.png';
import mp4Icon from '../assets/images/mp4_icon.png';

let loginUser="";
let userObject="";
let companyName="";
let clsWappCamp;


let totalFileAllow=5;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objVideo=[];
let vidIndexExisting=0;
let arrVidExistingFiles=[];

let objPdf=[];
let pdfIndexExisting=0;
let arrPdfExistingFiles=[];
class Add_Study_Material extends React.Component{
    constructor(props){

        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={listCategory:[],stVendoList:[],stBooksCategory:[]};
      clsWappCamp=this;
      //alert("i m in study material");
      }
      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getCategoriesMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"get_vendor",value:"yes"});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCategoriesMaster";
        CallMapMethod_CBack(clsWappCamp,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                //alert(JSON.stringify(listData.vendor_list));
                clsWappCamp.setState({listCategory:listData},()=>{
                
                //  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }

//  check file validation
      fileValidation(){
        let allowFile=true;
     let imgCount=objImages.length;
     let vidCount=objVideo.length;
     let pdfCount=objPdf.length;

     let vTotalCount=imgCount+vidCount+pdfCount;
    
     if(vTotalCount>=totalFileAllow){
      alert("Only "+totalFileAllow+" to be allowed .");
      allowFile=false;
     }
     return allowFile;
 }
 //  check file validation     
  rmPdfProc(data){
    
        if(document.getElementById("ref_pdf_"+data)!=null){
          document.getElementById("ref_pdf_"+data).remove();
        }
        
        this.lcRemoveJSON(data,"pdf");
      }    

PdfThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
  if(flImg==="-10"){
    alert(flName);
    return;
  }
   if(othInfo===""){
    othInfo="nm"+pdfIndexExisting+","+pdfIndexExisting;
  }
  pdfIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrPdfExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_pdf_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+pdfIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src="+pdfIcon+" /></a>" +
    "</div>";
  
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objPdf.push({ name: arrNmValue[1], value: flImg });
  $("#lsPdf").val("{\"objs\":" + JSON.stringify(objPdf) + "}");
  //alert(strSet);            
  $(".pdfLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappCamp, "_removeBtn"+pdfIndexExisting, "rmPdfProc", "2");
  //imgLibThumb
}      
rmVideoProc(data){
  if(document.getElementById("ref_vid_"+data)!=null){
    document.getElementById("ref_vid_"+data).remove();
  }
  
  this.lcRemoveJSON(data,"video");
}
VideoThumbBack(flImg,flName,fObject,othInfo=""){
  if(!this.fileValidation()){
    return;
  }
  if(flImg==="-10"){
    alert(flName);
    return;
  }

  if(othInfo===""){
    othInfo="nm"+vidIndexExisting+","+vidIndexExisting;
  }
  vidIndexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrVidExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+vidIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + mp3Icon + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
     strSet= "<div id=\"ref_vid_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+vidIndexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><img src="+mp4Icon+" /></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objVideo.push({ name: arrNmValue[1], value: flImg });
  $("#lsVideo").val("{\"objs\":" + JSON.stringify(objVideo) + "}");
  //alert(strSet);            
  $(".vidLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }

RespBkMthdMapp(clsWappCamp, "_removeBtn"+vidIndexExisting, "rmVideoProc", "2");
  //imgLibThumb
}      

/* Loc */

//==== New Logic of Image Thumbnail


rmFileProc(data,lsImages,thumbDiv){
  //alert(lsImages);
  if(lsImages==="" || lsImages==="0"){
    lsImages="lsImages"
  }
  if(thumbDiv==="" || thumbDiv==="0"){
    thumbDiv="ref_div_";
  }
  
  if(document.getElementById(thumbDiv+data)!=null){
    document.getElementById(thumbDiv+data).remove();
  }
  
  this.lcRemoveJSON(data,"image",lsImages,thumbDiv);
}
lcRemoveJSON(refKey,fType,lsImages,thumbDiv){
  var jsonP=JSON.parse($("#"+lsImages).val());
  var arrJson=jsonP.objs;
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      $("#"+lsImages).val(JSON.stringify(jsonP));
      break;
    }
  }
  objImages=arrJson;
}

ImageThumbBackNew(flImg, flName,fObject,othInfo="",lsElement="",thumbnail="",isMultiple=false){
  //alert(isMultiple);
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }
  if(lsElement===""){
    lsElement="lsImages"
  }
  let currentObject=null;
  let thumDiv="ref_div_";
  if(fObject){
    currentObject=fObject[0];
    if(currentObject.hasAttribute("thumbnail_div")){
      thumDiv=currentObject.getAttribute("thumbnail_div");
    }

  }

  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\"  param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }else if(flImg.indexOf("data:application/pdf")!==-1 || flImg.indexOf("data:application/pdf")!==-1){
      strSet= "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-2 col-md-2 col-sm-2 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\"  param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><img src="+pdfIcon+" /></a>" +
        "</div>";
    }

  


    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  
 // alert(objImages.length);

 let tmpObjImages=[];
 if($("#"+lsElement).val()!==""){
  if(isMultiple===true){
    //alert("i min multiple");
    tmpObjImages=JSON.parse($("#"+lsElement).val());
    tmpObjImages=tmpObjImages.objs;
  }
 }
 tmpObjImages.push({ name: arrNmValue[1], value: flImg });
 $("#"+lsElement).val("{\"objs\":" + JSON.stringify(tmpObjImages) + "}");
  
  //alert(tmpObjImages.length);
  //alert(strSet);
if(thumbnail===""){
  thumbnail="imgLibThumb";
}
//alert(isMultiple);
if(isMultiple===false){
  $("."+thumbnail).html(strSet);
}else{
  //alert("i m in append");
  $("."+thumbnail).append(strSet);
}

  
  
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsWappCamp, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
}

// ****** End of Logic New 


saveWappCompaign(){
  //alert("Sending WAPP");
  //alert($("#contact_no").val());
/*
  let checkChar= $("#msg_text").val();
   let result=detectEmojisAndSpecialCharacters(checkChar);
  
if(result.emojis.length!==0 || result.specialCharacters.length!==0){
   alert("Special Characters and Emojis not allowd in text Message");
   return; 
}
*/


  
  GetImgCertUser_CBack(clsWappCamp,"#frmWappCamp","addStudyMaterial",function(checkData,checkMsg,userMsg){
    
    if(checkData===-10){
      alert("Kindly fill required fields");
      return;
    }else if(checkData==="001"){
      Custom_Alert("Successfully Added",checkMsg,true);
        
    }else if(checkData==="01") {
      Custom_Alert("Not added",checkMsg,false);
      //alert(checkMsg);
    }
    
  });

  
// Custom_Alert("WAPP CAMPAIGN","<span>Test1</span><span>Test2</span>")
  
 
}

 isFloat(n) {
  let checkNum = false;
  if (Number(n)) {
      checkNum = true;
  } else if (n % 1 !== 0) {
      checkNum = true;
  } else {
      checkNum = false;
  }

  return checkNum;
}
onChangeEntityCategory=(event)=>{
  //alert(event.target.value);
  //alert(JSON.stringify(clsWappCamp.state.listCategory));
  const listFltr = d =>(d.access_role_id.toString()===event.target.value.toString() && d.top_id.toString()!=="0");
  let lsTmpBooksCat=clsWappCamp.state.listCategory.filter(listFltr);
  //alert(JSON.stringify(lsTmpBooksCat));
  clsWappCamp.setState({stBooksCategory:lsTmpBooksCat},()=>{
    //clsWappCamp.forceUpdateHandler();
  });

//  stBooksCategory
}
 
componentDidMount(){
    
  this.getCategories();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);

//$(".cleditor").summernote();
$(".cd_input_change").change(function () {
  let objPreCost = document.getElementById("pre_cost");
  let objOfferPer = document.getElementById("offer_per");
  let objFCost = document.getElementById("txt_cost");

    let valPreCost = 0;
    let valOfferPer = 0;
    let valFCost = 0;

    if (clsWappCamp.isFloat(objPreCost.value.trim())) {
      valPreCost = objPreCost.value.trim();
    }
    if (clsWappCamp.isFloat(objOfferPer.value.trim())) {
      valOfferPer = objOfferPer.value.trim();
    }
    if (clsWappCamp.isFloat(objFCost.value.trim())) {
      valFCost = objFCost.value.trim();
    }

    if(valOfferPer!==0){
      objFCost.value = valPreCost-(valPreCost * valOfferPer / 100).toFixed(2);
    }else{
      objFCost.value=objPreCost.value;
    }



})
}

   render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-12 col-lg-12"> 
        
<section class="content">
<div class="container-fluid">
<div class="card card-secondary">
<div class="card-header">
                                <h3 class="card-title">Add Study Material</h3>
                                <a className="btn btn-sm btn-danger float-right" href="../view_study_material">View Study Material</a>
                            </div>
                            <form class="form-group" id="frmWappCamp" name="frmWappCamp" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addStudyMaterial" />
                            <input type="hidden" name="m_type" id="m_type" value="addStudyMaterial" />
                            <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                          
                            <input type="hidden" name="lsBookTumb" id="lsBookTumb" />
                            <input type="hidden" name="lsImages" id="lsImages"/>
                            <input type="hidden" name="lsVideo" id="lsVideo"/>
                            <input type="hidden" name="lsPdf" id="lsPdf"/>
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Entity *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="cmbTopCategory" name="cmbTopCategory" onChange={this.onChangeEntityCategory} className="form-control required">
                                                  <option value="0">-Select Category-</option>
                                                  {this.state.listCategory.map((item,index)=>{
                                                    if(item["category_type"]==="2"){
                                                      return(<option value={item.id}>{item.category_name}</option>)
                                                    }
                                                    
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                  
                                  <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Books Category *</label>
                                            <div className="input-group input-group-sm">
                                                <select id="cmbCategory" name="cmbCategory" className="form-control required">
                                                  <option value="0">-Select Category-</option>
                                                  {this.state.stBooksCategory.map((item,index)=>{
                                                    return(<option value={item.id}>{item.category_name}</option>)
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>

                                <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Book Title</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="topic_title" name="topic_title" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>

                                 
                                  
                                 
                                    
                                </div>
                                <div class="row">
                                <div className="col-lg-3 col-md-3">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">ISBN/ISSN</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="task_no" name="task_no" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                <div className="col-lg-2 col-md-2">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Display Index</label>
                                            <div className="input-group input-group-sm">
                                                <input type="number" id="task_index" name="task_index" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Author</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="author" name="author" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Publisher</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="publisher" name="publisher" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                               
             
{/* image upload section */}
<div className="col-md-12">
<div className="row">
{/* file image upload */}

<div className="col-md-4">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Books Image Thumbnail </h3><small> (Max File Size 1MB, Only 1 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNew" thumbnail_div={"main_img_div"} lsElement={"lsBookTumb"} thumbnail={"imgLibThumb"} name="customFileBr" id="customFileBr" accept="image/*" size={100000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of file image upload */}

                               {/* file other image upload */}

<div className="col-md-8">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Upload e-Book </h3><small> (Max File Size 20MB) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgOthLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNew" thumbnail_div={"oth_img_div"} lsElement={"lsImages"} thumbnail={"imgOthLibThumb"} multiple name="customOthFileBr" id="customOthFileBr" accept="image/*" size={200000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customOthFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of other file image upload */}
</div>
</div>
{/* end of Image Upload Section */}

                                    
                               
<div className="col-md-8">
    <div className="row">
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Short Description *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtProDes" name="txtProDes"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">OverView *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtOverView" name="txtOverView"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Features *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtFeatures" name="txtFeatures"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Specifications *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="txtSpecifications" name="txtSpecifications"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>  
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>this.saveWappCompaign()} class="btn btn-primary">Save and Review</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      
</div>
    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

          </div>)
      }
}
export default Add_Study_Material;