import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import {Comp_Add_Site_Master} from '../components/comp_add_site_master';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';


let loginUser="";
let userObject="";
let companyName="";
let clsSiteMaster;

let rf="0";
class Institutions extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListSiteMaster:[],stSiteMaster:{}};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsSiteMaster=this;
      }

      getSiteMaster(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"get_site_master"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        //map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="get_site_master";
        CallMapMethod_CBack(clsSiteMaster,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsSiteMaster.setState({stListSiteMaster:listData},()=>{
                clsSiteMaster.forceUpdateHandler();
                });
              }
        });
      }
      okResProCate(data,msg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful",msg,true);
          //clsSiteMaster.getSiteMaster();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",msg,false);
        }
        // alert(data);
      }


 onEditFeeCategory(siteMasterData){
  // method will work on click on Credit Button Link to set State
  clsSiteMaster.setState({stSiteMaster:siteMasterData},()=>{
    
  })

 }             
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getSiteMaster();

$('#divAddSiteMaster').on('hidden.bs.modal', function () {
 clsSiteMaster.getSiteMaster();
});
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">


<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getSiteMaster()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()} className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Institutions</b></h3>
                <a className="btn btn-sm btn-danger float-right" data-bs-toggle="modal" data-bs-target="#divAddSiteMaster" data-toggle="modal" data-target="#divAddSiteMaster" href="javascript:void(0)">Add New</a>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Institution Name</th>
                      <th>Institution Code</th>
                      <th>University</th>
                      <th>description</th>
                      <th>Status</th>
                      <th>Is Active</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListSiteMaster.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                        
                  <td>{item["company_name"]}</td>
                  <td>{item["contact_no"]}</td>
                  <td>{item["site_name"]}</td>
                  <td>{item["details"]}</td>
                  <td>{item.status}</td>
                  <td>{item.is_active}</td>
                  {/* <td><a  data-toggle="modal" data-target="#divFeeCategory" onClick={()=>this.onEditFeeCategory(item)}  href="javascript:void(0)">Edit</a>|</td> */}
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
{/* <Comp_Add_Site_Master logUser={userObject}/> */}

    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Institutions;