import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';


let loginUser="";
let userObject="";
let companyName="";
let clsCategoryMaster;

let rf="0";
class Academic_Year extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ listUsers:[],stYearMasterData:{}};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsCategoryMaster=this;
      }

      getSessionYear(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getSessionYear"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        
        map.push({name:"ser_text",value:$("#ser_text").val()});
       
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getSessionYear";
        CallMapMethod_CBack(clsCategoryMaster,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsCategoryMaster.setState({listUsers:listData},()=>{
                  //window.getPagination("#tbData");
                  clsCategoryMaster.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsCategoryMaster.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
              }
        });
      }
      okSucc(data,msg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful",msg,true);
          clsCategoryMaster.getSessionYear();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",msg,false);
        }
        // alert(data);
      }
okSuccCreditB(data,msg){
  //alert(data);
  if(data===-10){
    Custom_Alert("Validation","Fill Required Fileds",false);
    
    return;
  }else{
    //alert(data+msg);
    Custom_Alert("Completed",msg,true);
    
  }
    
}

 onEditYearMaster(yearData){
  // method will work on click on Credit Button Link to set State
  clsCategoryMaster.setState({stYearMasterData:yearData},()=>{
    clsCategoryMaster.forceUpdateHandler();
  })

 }             
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
//this.getSessionYear();

$('#divSessionYear').on('hidden.bs.modal', function () {
  //alert("i m in hide");
  clsCategoryMaster.setState({stYearMasterData:{"nn":"00"}});
  clsCategoryMaster.forceUpdateHandler();
 
});
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">


<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getUsers()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Academic Year</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" data-bs-toggle="modal" data-bs-target="#divSessionYear" data-toggle="modal" data-target="#divSessionYear" href="javascript:void(0)">Add New</a>
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Academic Year</th>
                      <th>YearFrom</th>
                      <th>Year To</th>
                      <th>Status</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.listUsers.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item.session_name}</td>  
                  <td>{item.year_from}</td>
                  <td>{item.year_to}</td>
                  <td>{item.status}</td>
                  <td>{item.is_active}</td>
                  <td><a data-bs-toggle="modal" data-bs-target="#divSessionYear" data-toggle="modal" data-target="#divSessionYear" onClick={()=>this.onEditYearMaster(item)}  href="javascript:void(0)">Edit</a> |</td>
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<div className="modal fade bd-example-modal-xl"  id="divSessionYear" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">{typeof this.state.stYearMasterData.id==="undefined"?"Add New Academic Year":"Edit Academic Year"}</h5>
        <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmSessionYear" name="frmSessionYear">
     
      <div className="modal-body">
      <div className="row">
                {/* <!-- left column --> */}
                <div className="col-md-12">
                    <div className="card card-danger">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="createSessionYear" />
                            <input type="hidden" name="m_type" id="m_type" value="createSessionYear" />
                            <input type="hidden" name="type_id" id="type_id" value="2" />
                            <input type="hidden" name="upper_id" id="upper_id" value={userObject[0].app_user_id} />
                            <input type="hidden" name="session_year_id" id="session_year_id" value={typeof this.state.stYearMasterData.id!=="undefined"?btoa(this.state.stYearMasterData.id):"0"}/>
                            <div className="card-body">
                                <div className="row">
                                
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Year From *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="year_from" name="year_from" className="form-control required" defaultValue={this.state.stYearMasterData.year_from} placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Year To *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="year_to" name="year_to" className="form-control required" defaultValue={this.state.stYearMasterData.year_to} placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Session Name *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="session_name" name="session_name" className="form-control required" defaultValue={this.state.stYearMasterData.session_name} placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Is Active *</label>
                                            <div className="input-group input-group-sm">
                                                <select className="form-control required" id="is_active" name="is_active">
                                                  <option value={"0"}>Select</option>
                                                  <option selected={this.state.stYearMasterData.is_active==="Active"} value={"Active"}>Active</option>
                                                  <option selected={this.state.stYearMasterData.is_active==="Deactive"} value={"Deactive"}>Deactive</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                  
                                   
                                </div>
                              
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                            </div>
                        
                    </div>
                </div>
            </div>
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">{typeof this.state.stYearMasterData.id==="undefined"?"Add New Year":"Update Now"}</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Academic_Year;