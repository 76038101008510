import React from 'react';
import mui_logo from '../assets/ent_logo/mui.png';
import cmmi_logo from '../assets/ent_logo/cmmi.png';
import imei_logo from '../assets/ent_logo/imei.png';
export const Comp_Entity_Contents=(props)=>{
return(<div class="card">
    <div class="card-header">
      <h5 class="card-title">Entity Wise</h5>

      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fa fa-minus"></i>
        </button>
        <div class="btn-group">
          <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-wrench"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" role="menu">
            <a href="#" class="dropdown-item">Action</a>
            <a href="#" class="dropdown-item">Another action</a>
            <a href="#" class="dropdown-item">Something else here</a>
            <a class="dropdown-divider"></a>
            <a href="#" class="dropdown-item">Separated link</a>
          </div>
        </div>
        <button type="button" class="btn btn-tool" data-card-widget="remove">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    
    <div class="card-body">
    {/* <!-- Info boxes --> */}
        <div class="row">

             {/* invoice order view */}
          {props.dashboardData?.mui?
             <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-info">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red bg-info1 elevation-11">
                    
                    <img src={mui_logo} height={"80"}/>
                    </span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"The Maritime Union of India"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a href="../view_members" className="nav-link">
                      Members <span className="float-right badge bg-primary">{props.dashboardData.mui["members"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                      eBooks <span className="float-right badge bg-danger">{props.dashboardData.mui["e_books"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                       eMagazines <span className="float-right badge bg-success">{props.dashboardData.mui["e_magazines"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../staff_users" className="nav-link">
                      Staff/Users <span className="float-right badge bg-success">{props.dashboardData.mui["staff_users"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Average Active Candidates <span className="float-right badge bg-success">{"27"+" /Day"}</span>
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      Total Order(till date) <span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div> 
    :''}
          {/* end of invoice order view */}

           {/* dau view */}
           {props.dashboardData?.cmmi?
           <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-info">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red bg-info1 elevation-11">
                    <img src={cmmi_logo} height={"80"} />
                    </span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"The Company of Master Mariners of India"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a href={"../view_members"} className="nav-link">
                      Members <span className="float-right badge bg-primary">{props.dashboardData.cmmi["members"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                    Technical Books <span className="float-right badge bg-danger">{props.dashboardData.cmmi["technical_books"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                    Non-Technical Books <span className="float-right badge bg-danger">{props.dashboardData.cmmi["non_technical_books"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                      eMagazines <span className="float-right badge bg-success">{props.dashboardData.cmmi["e_magazines"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../staff_users" className="nav-link">
                      Staff/Users <span className="float-right badge bg-success">{props.dashboardData.cmmi["staff_users"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                      Average Active Candidates <span className="float-right badge bg-success">{"27"+" /Day"}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          :''}
{/*end dau view */}

 {/* dau view */}
 {props.dashboardData?.cmmi?
           <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-info">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red bg-info1 elevation-11">
                 <img src={imei_logo} height={"80"} />
                  </span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"The Institute of Marine Engineers of India"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a href="../view_members" className="nav-link">
                      Members <span className="float-right badge bg-primary">{props.dashboardData.imei["members"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                      Technical Books <span className="float-right badge bg-danger">{props.dashboardData.imei["technical_books"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                      Non-Technical Books <span className="float-right badge bg-success">{props.dashboardData.imei["non_technical_books"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../view_study_material" className="nav-link">
                      eMagazines <span className="float-right badge bg-success">{props.dashboardData.cmmi["e_magazines"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../staff_users" className="nav-link">
                      Staff/Users <span className="float-right badge bg-success">{props.dashboardData.imei["staff_users"]}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                    Average Active Candidates <span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          :''}
{/*end dau view */}
            {/* end of component tyep */}
                 </div>
{/* end of quic activity */}

      
    </div>
    
    {/* <div class="card-footer">
      <div class="row">
      </div>
      
    </div> */}
    
  </div>)
}

export default Comp_Entity_Contents;