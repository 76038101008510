import {useEffect} from 'react';
import {getWaUrlPath} from '../function_lib/common_lib';
import './comp_bhandarkar_book_card.css'

const Comp_Bhandarkar_Book_Card=(props)=>{
  let _filePath=getWaUrlPath();
 const onClickChaptersAccord=(dataI)=>{
  let acc = document.getElementById("btn_chapters"+dataI);
  if(acc){
    acc.addEventListener("click", function() {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      } 
    });
  }
  }
  useEffect(()=>{
    
  },[props.bookList])
    return(<>
      {props.bookList.map((item,index)=>{
        let topCats=[];
        if(item["top_cats"]){
          topCats=item["top_cats"].split('>');
        }
        //let topCats=item["top_cats"].split('<');
        return <div class="book-card bg-white mb-3">
          
        <div class="row g-0">
          {/* <!-- Icon --> */}
          <div class="col-4 col-md-2 left-section">
          <a onClick={()=>props.onViewBook(item)} href={"javascript:void(0)"} data-bs-toggle="modal" data-bs-target="#divPdfView">
            <div class="book-icon">
              {item["book_thumbs"]===""?<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
              </svg>:<img src={_filePath+item["book_thumbs"]} height={"40px"}/>
              }
              
            </div>
            <div class="book-code text-center mt-2">ISBN/ISSN: {item["task_no"]}</div>
            </a>
          </div>
          
          {/* <!-- Info --> */}
          <div class="col-8 col-md-10 text-start">
            <div class="p-3">
            <a onClick={()=>props.onViewBook(item)} href={"javascript:void(0)"} data-bs-toggle="modal" data-bs-target="#divPdfView">
              <h6 class="book-title mb-2">{item["topic_title"]}</h6>
              </a>
              <p class="book-author mb-2">{item["author"]}</p>
              <div class="book-categories">
                {topCats.map((itemCats,indexCats)=>{
                  return <span class={"book-category category-"+indexCats}>{itemCats}</span> 
                })}
                {/* {item["top_cats"]} */}
                {/* <span class="book-category frst-category">Technical Books</span>
                <span class="book-category secnd-category">Diesel Engine</span>
                <span class="book-category third-category">Main and Aux Engines</span> */}
              </div>
              
            
            </div>
          </div>
        </div>
        <div class="book_categories">
                {/* accordial */}
                <button onClick={onClickChaptersAccord(item["id"])} id={"btn_chapters"+item["id"]} class="chapers-accord">Number of Chapters - 7</button>
<div id={"pan"+item["id"]} class="chapers-accord-panel">
  <div class="book_categories">
  <p></p>
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"1-ArticlesoftheIntlConv_pg1-5"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"2-Protocolof1988relating_pg6-9"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"3-Chapter1-GeneralConsolidatedtext_pg10-23"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"4-ChapterII-1ConstructionStructure_pg24-107"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"5-ChapterII-2Construction-Fire_pg108-220"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"6-ChapterIII-Life-savingappliances_pg221-253"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"7-ChapterIV-Radiocommunications_pg254-268"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"8-ChapterV-Safetyofnavigation_pg269-300"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"9-ChapterVI-Carriageofcargoes_pg301-308"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"10-ChapterVII-Carriageofdangerous_pg309-316"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"11-ChapterVIII-Nuclearships_pg317-320"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"12-ChapterIX-Managementfor_pg321-323"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"13-ChapterX-Safety_measures_pg324-326"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"14-ChapterXI-1Special_measures_pg327-332"}</a> 
  <a href={"javascript:void(0)"} onClick={()=>props.onViewBook(item)} class={"book-category books-chapters"}>{"15-ChapterXI-2Special_measuresto_pg333-342"}</a> 
  
  </div>
  
</div>

              </div>
      </div>
      })}
            
         </>
     

    );
}

export default Comp_Bhandarkar_Book_Card;