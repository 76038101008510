import {RespBkMthdMapp_CBack,setFilters} from '../function_lib/common_lib';
import React,{useEffect} from 'react';
import "./comp_bhandarkar_sidebar_menu.css";

const expoLevelSubMenu=(listCategory,parent_id,id)=>{
  
  //const listFltr = d =>((d.access_role_id.toString()==="0".toString() && d.top_id.toString()!=="0".toString() && d.category_type.toString()==="6".toString()) || (d.top_id.toString()===id.toString()));
  const listFltr=d=>(d.top_id.toString()===id.toString())
  let lsTmpBooksCat=listCategory.filter(listFltr);
 // alert(JSON.stringify(listCategory));
  return lsTmpBooksCat;
}

const Comp_Bhandarkar_Sidebar_Menu = (props) => {
  useEffect(()=>{
    //alert("i m in useEffect");
    RespBkMthdMapp_CBack(this,"hevent_flt_rightbar",function(data){
     
      //alert(JSON.stringify(data));
      props.runSearch(data);
    },"");
    RespBkMthdMapp_CBack(this,"reset_filters",function(data){
      //alert(JSON.stringify(data));
      props.runSearch(data);
    });
  },[props.listCategories]);
  
  return (
    <div class="bhandarkar-library">
      <div class="sidebar ">
        <div class="nav flex-column">
          {props.listCategories.map((item,index)=>{
           
            if(item["category_type"]==="2"){
              
              let divIndex="level"+item["id"];
            
            return <div class="nav-item frst">
              
            <label href={"#"+divIndex} data-bs-toggle="collapse" class="nav-link hevent_flt_rightbar">
           <span><input class="checkbox_sider  hcls_flt_books_cate" value={item["id"]} type="checkbox"/>{item["category_name"]}</span>
              <span class="collapse-icon">▼</span>
            </label>
            {expoLevelSubMenu(props.listCategories,item["top_id"],item["id"]).map((item2,index2)=>{
              let divIndex2="level"+item2["id"];
              return <div class="collapse" id={divIndex}>
              <div class="sub-menu">
                <label href={"#"+divIndex2} onclick="event.preventDefault();" data-bs-toggle="collapse" class="nav-link hevent_flt_rightbar">
                <input class="checkbox_sider hcls_flt_books_cate" value={item2["id"]} type="checkbox"/>{item2["category_name"]}
                  <span class="collapse-icon">▼</span>
                </label>
                {expoLevelSubMenu(props.listCategories,item2["top_id"],item2["id"]).map((item3,index3)=>{
                  let divIndex3="level"+item3["id"];
                  return <div class="collapse" id={divIndex2}>
                  <div class="sub-menu">
                    <label href={"#"+divIndex3} data-bs-toggle="collapse" class="nav-link hevent_flt_rightbar level-2">
                  <span><input class="checkbox_sider  hcls_flt_books_cate" name={item3["id"]} value={item3["id"]} type="checkbox"/>{item3["category_name"]}</span>
                      <span class="collapse-icon">▼</span>
                    </label>
                    {expoLevelSubMenu(props.listCategories,item3["top_id"],item3["id"]).map((item4,index4)=>{
                      let divIndex4="level"+item4["id"];
return <div class="collapse" id={divIndex3}>
 
<label href="#" class="nav-link level-3 hevent_flt_rightbar">
<input class="checkbox_sider11 hcls_flt_books_cate" value={item4["id"]} value={item4["id"]} for={item4["id"]} type="checkbox"/>  

{item4["category_name"]}

</label>


{expoLevelSubMenu(props.listCategories,item4["top_id"],item4["id"]).map((item5,index5)=>{
return <div class="collapse" id="level1-1-1-1">
<a href="#" class="nav-link level-4">
  Level 1.4
</a>
</div>
})}

</div>
                    })}
                    

                  </div>
                </div>
                })}
                
              </div>
            </div>
            })}
            
          </div>
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Comp_Bhandarkar_Sidebar_Menu;
