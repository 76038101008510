import $, { extend } from 'jquery';
import React,{useEffect,useContext,useState} from 'react';
import { GlobalContext } from '../context';
import {CallMapMethod_CBack,getAppUrl} from '../function_lib/common_lib';
import Select from 'react-select';
import axios from 'axios';

export function GetAdmins(){
    const {gbStItemInfo} =useContext(GlobalContext);
    if(gbStItemInfo===0 || gbStItemInfo===null || gbStItemInfo===""){
        //alert(JSON.stringify(gbStItemInfo));
    return gbStItemInfo;
   
    }else{
        return [];
    }
}
export async function SetAdminsComps_Async(userObject, gbStItemInfo, setItemInfo) {
    if (gbStItemInfo.length === 0) {
        //alert("i m in call");
        //let map=[];
        const map = {
            curl_type: "get_user_info",
            res_func: "callBackrrr",
            local_token: userObject[0].id,
            user_type: userObject[0].type,
            type_master_id: "2",
            access_type: userObject[0].access_type,
            ser_text: "",
            upper_id: userObject[0].upper_id,
          };
        

      const apiUrl = getAppUrl() + "get_user_info";
      try {
        const response = await axios.post(apiUrl, map);
        //alert(response.data);
        var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
        var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
        let parsedData = JSON.parse(checkData);
        
        if (parsedData) {
          setItemInfo(parsedData); // Update context state
          return parsedData;
        }
      } catch (error) {
        //alert("i m in aerror");
        console.error("Error fetching data:", error);
      }
    }
    return gbStItemInfo;
  }
export function SetAdminsComps(userObject){
     //alert("i m in call");
     const {gbStItemInfo, setItemInfo} =useContext(GlobalContext);
     const [getLocalList,setLocalList]=useState([]);
     //const {gbStItemInfo, setItemInfo} =useState(null);
 
     const fetchData = async () =>{
         if(gbStItemInfo.length===0){
             let map=[];
             //alert("i m in sync "+props.userObject[0].id);
             map.push({name:"curl_type",value:"get_user_info"});
             map.push({name:"res_func",value:"callBackrrr"});
             map.push({name:"local_token",value:userObject[0].id});
             map.push({name:"user_type",value:userObject[0].type});
             map.push({name:"type_master_id",value:"2"});
             map.push({name:"access_type",value:userObject[0].access_type});
             //map.push({name:"ser_userType",value:$("#ser_userType").val()});
             map.push({name:"ser_text",value:$("#ser_text").val()});
             //alert(status);
            
             map.push({name:"upper_id",value:userObject[0].upper_id});
             map["res_func"]="callBackrrr";
             map["curl_type"]="get_user_info";
             const apiUrl = getAppUrl()+"get_user_info";
             const response = await axios.post(apiUrl, map);
             var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
             var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
             //alert(checkData);
             if(checkData!=="0" && checkData!==""){
                setItemInfo(JSON.parse(checkData));
             }
             return gbStItemInfo;
             //  CallMapMethod_CBack(this,map,function(data,msg){
            //      //alert(data);
            //      if(data!=="0" && data!==""){
            //        let lsData=JSON.parse(data);
            //        //setItemInfo(lsData);
            //        setLocalList(lsData);
       
            //      }
            //    })
     }else{
        //setLocalList(gbStItemInfo);
     }
 }
//  let ceck=fetchData();
//  alert(ceck);
        
 return getLocalList;
}
export function SetAdmins(props){
    //alert("i m in call");
    const {gbStItemInfo, setItemInfo} =useContext(GlobalContext);
    //const {gbStItemInfo, setItemInfo} =useState(null);

    const fetchData=()=>{
        if(gbStItemInfo===0 || gbStItemInfo===null || gbStItemInfo===""){
            let map=[];
            //alert("i m in sync");
            map.push({name:"curl_type",value:"get_user_info"});
            map.push({name:"res_func",value:"callBackrrr"});
            map.push({name:"local_token",value:props.userObject[0].id});
            map.push({name:"user_type",value:props.userObject[0].type});
            map.push({name:"type_master_id",value:"2"});
            map.push({name:"access_type",value:props.userObject[0].access_type});
            //map.push({name:"ser_userType",value:$("#ser_userType").val()});
            map.push({name:"ser_text",value:$("#ser_text").val()});
            //alert(status);
           
            map.push({name:"upper_id",value:props.userObject[0].upper_id});
            map["res_func"]="callBackrrr";
            map["curl_type"]="get_user_info";
           
            CallMapMethod_CBack(this,map,function(data,msg){
                //alert("ttot-=-"+data);
                if(data!=="0" && data!==""){
                  let lsData=JSON.parse(data);
                  setItemInfo(lsData);
                  if(typeof props.setParentState!=="undefined"){
                    props.setParentState(lsData); 
                  }
      
                }
              })
    }else{
        if(typeof props.setParentState!=="undefined"){
            props.setParentState(gbStItemInfo); 
          }
    }
}
        
        
       
        
        
        

        fetchData();
return  () => {
    // This code will run when the component unmounts
    //console.log('Component unmounted');
  };
}
export default SetAdmins;