import React from 'react';
export const Comp_User_Activity=(props)=>{
return(<div class="card">
    <div class="card-header">
      <h5 class="card-title">Users Activity</h5>

      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fa fa-minus"></i>
        </button>
        <div class="btn-group">
          <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-wrench"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" role="menu">
            <a href="#" class="dropdown-item">Action</a>
            <a href="#" class="dropdown-item">Another action</a>
            <a href="#" class="dropdown-item">Something else here</a>
            <a class="dropdown-divider"></a>
            <a href="#" class="dropdown-item">Separated link</a>
          </div>
        </div>
        <button type="button" class="btn btn-tool" data-card-widget="remove">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    
    <div class="card-body">
      <div class="row">
        <div class="col-md-8">
          <p class="text-center">
            <strong>----</strong>
          </p>
          <div class="d-flex">
          {/* info-box1 */}
          
          
          {/* <a class="info-box mb-3 bg-warning mr-2" href={"../Comp_User_Activity"}>
              <span class="info-box-icon"><i class="fa fa-university white"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Total Entities</span>
                <span class="info-box-number">3</span>
              </div>
              </a> */}
           
           

 {/* info-box2 */}
            {/* <a href="javascript:void(0)" class="info-box mb-3 bg-success mr-2">
              <span class="info-box-icon"><i class="fa fa-address-book-o white"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Faculties/Staff</span>
                <span class="info-box-number">18</span>
              </div>
            </a> */}
</div>

<div class="d-flex">
 {/* info-box3 */}
            {/* <a href="javascript:void(0)" class="info-box mb-3 bg-danger mr-2">
              <span class="info-box-icon"><i class="fa fa-graduation-cap white"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Students</span>
                <span class="info-box-number">200</span>
              </div>
            </a> */}

 {/* info-box4 */}
            {/* <a href="javascript:void(0)" class="info-box mb-3 bg-info mr-2">
              <span class="info-box-icon"><i class="fa fa-id-card-o white"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Tab User(s) Activated </span>
                <span class="info-box-number">200</span>
              </div>
            </a> */}

</div>
          
        </div>
        
        {/* <div class="col-md-4">
          <p class="text-center">
            <strong>Goal Completion</strong>
          </p>

          <div class="progress-group">
            Task Attempt
            <span class="float-right"><b>160</b>/200</span>
            <div class="progress progress-sm">
              <div class="progress-bar bg-primary" style={{"width": "80%"}}></div>
            </div>
          </div>
          

          <div class="progress-group">
            Quiz Attempt
            <span class="float-right"><b>310</b>/400</span>
            <div class="progress progress-sm">
              <div class="progress-bar bg-danger" style={{"width":"75%"}}></div>
            </div>
          </div>

          
          <div class="progress-group">
            <span class="progress-text">Study Ratio Candidate vs Books</span>
            <span class="float-right"><b>480</b>/800</span>
            <div class="progress progress-sm">
              <div class="progress-bar bg-success" style={{"width":"60%"}}></div>
            </div>
          </div>

          
          <div class="progress-group">
            Unattempted Student Task
            <span class="float-right"><b>250</b>/500</span>
            <div class="progress progress-sm">
              <div class="progress-bar bg-warning" style={{"width":"50%"}}></div>
            </div>
          </div>
          
        </div> */}
        
      </div>
      
    </div>
    
    <div class="card-footer">
      <div class="row">
        <div class="col-sm-3 col-6">
          <div class="description-block border-right">
            <span class="description-percentage text-success"><i class="fa fa-caret-up"></i> 100%</span>
            <h5 class="description-header">1412</h5>
            <span class="description-text">Total Study Material</span>
          </div>
         
        </div>
        
        <div class="col-sm-3 col-6">
          <div class="description-block border-right">
            <span class="description-percentage text-warning"><i class="fa fa-caret-left"></i> 9.4%</span>
            <h5 class="description-header">134</h5>
            <span class="description-text">Audio Books</span>
          </div>
          
        </div>
        
        <div class="col-sm-3 col-6">
          <div class="description-block border-right">
            <span class="description-percentage text-success"><i class="fa fa-caret-up"></i> 5.2%</span>
            <h5 class="description-header">78</h5>
            <span class="description-text">Videos/Animation</span>
          </div>
          
        </div>
       
        <div class="col-sm-3 col-6">
          <div class="description-block">
            <span class="description-percentage text-danger"><i class="fa fa-caret-down"></i> 84.9%</span>
            <h5 class="description-header">1200</h5>
            <span class="description-text">Pdf/Other Doccuments</span>
          </div>
          
        </div>
      </div>
      
    </div>
    
  </div>)
}

export default Comp_User_Activity;