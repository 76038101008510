import React,{useEffect,useContext} from 'react';
import { GlobalContext } from '../context';
export function Comp_Add_Employee(props){
    const {gbStItemInfo} =useContext(GlobalContext);
  
   useEffect(()=>{
    
    //alert(JSON.stringify(gbStItemInfo));
   },[props.editUser]);
  
    return(<div className="modal fade bd-example-modal-xl"  id="divAddEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Add New Staff</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAddEmployee" name="frmAddEmployee">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={("okRespCreateUser")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={("addStaffUser")} />
                              <input type="hidden" name="m_type" id="m_type" value={("addStaffUser")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_master_id" id="type_master_id" value={btoa("3")} />
                              <input type="hidden" name="user_info_id" id="user_info_id" value={typeof props.editUser.id !=="undefined"?btoa(props.editUser.id):"0"}/>
                              
                              <div className="card-body">
                              <div className="row">
                                
                                <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Admin</label>
                                            <div className="input-group input-group-sm">
                                                <select id="parent_id" name="parent_id" className="form-control required12">
                                                  <option value="0">-Select Admin-</option>
                                                  {gbStItemInfo?.map((item,index)=>{
                                                    return(<option value={item.id} >{item.entity_code}</option>)
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Person Name *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="person_name" name="person_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Contact No *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="contact_no" name="contact_no" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Email *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="email" name="email" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">UserName *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="user_name" name="user_name" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Password *</label>
                                            <div className="input-group input-group-sm">
                                                <input type="password" id="password" name="password" className="form-control required" placeholder="" />
                                            </div>
                                        </div>
                                    </div>

                              </div>      
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Save</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Add_Employee;