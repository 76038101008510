import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';


//import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
//import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
//import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';
import listData from '../function_lib/customeData';
import Comp_Edit_Truck_Out from '../ledger_component/comp_edit_truck_out';
import Comp_Add_Trans_Item from '../components/comp_add_trans_item';
import Comp_Search_Pan from '../components/comp_search_pan';

import Comp_Print_Report from '../components/comp_print_report';
import Comp_View_Ent_Logs from '../components/comp_view_ent_logs';

import { Link } from 'react-router-dom';

let loginUser="";
let userObject="";
let companyName="";
let clsViewTruckOut;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];

let _sumAmount=0;
let _sumNetAmount=0;
let _objAccess={};
class View_Truck_Out extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
          //alert(JSON.stringify(_objAccess));
      }
      this.state={ stListTransactions:[],stInvoice:{},stPtrInvoice:[],stPtrProduct:[],stPtrPayment:[],
    stEditInvoice:[],stEditProduct:[],stEditPayment:[],stOutStanding:[],stLogState:{},
  stEditPopupItem:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewTruckOut=this;
      }

      setLogState(item){
        let map={"table_name":"invoice","table_id":item.invoice_id}
        clsViewTruckOut.setState({stLogState:map});
      }

      deletePurchaseItem(item){
        if(window.confirm("Confirm to delete entry permanently. ")){
        
        let map=[];
        map.push({name:"curl_type",value:"delete_p_item"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"delete_p_item_id",value:btoa(item.purchase_item_id)})
        map["res_func"]="callBackrrr";
        map["curl_type"]="delete_p_item";
        CallMapMethod_CBack(clsViewTruckOut,map,function(data,msg){
          if(data==="001"){
            alert("Deleted Successfully");
            clsViewTruckOut.getTruckProWise();
          }
        });
      }
      }

      getEditInvoice(item){
        
        let map=[];
        map.push({name:"curl_type",value:"getInvoice"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"inventory_ledger_id",value:btoa("2")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"invoice_id",value:btoa(item.invoice_id)})
        map["res_func"]="callBackrrr";
        map["curl_type"]="getInvoice";
        
        CallMapMethod_CBack(clsViewTruckOut,map,function(data,msg){
          //alert(data);
          if(data!=="0"){
            let lsData=JSON.parse(data);
            clsViewTruckOut.setState({stEditInvoice:lsData["invoice"],stEditProduct:lsData["purchase_item"],
              stEditPayment:lsData["account_inventory"]
            })
          }
        })
      }


      getTruckProWise(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_itemwisereport_new"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"inventory_ledger_id",value:btoa("2")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});

        if(document.getElementById("ser_party_info_id")!==null){
          //alert($("#ser_party_info_id").val());
          map.push({name:"ser_party_info_id",value:$("#ser_party_info_id").val()});
        }
        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        if(document.getElementById("ser_site_id")!==null){
          map.push({name:"ser_site_id",value:$("#ser_site_id").val()});
        }

        if(document.getElementById("ser_vehicle")!==null){
          //alert("i m in vehicle "+$("#ser_vehicle").val());
          map.push({name:"ser_vehicle",value:$("#ser_vehicle").val()});
        }
        
        if(document.getElementById("ser_payment_mode")!==null){
          map.push({name:"ser_payment_mode",value:$("#ser_payment_mode").val()});
        }

        if(document.getElementById("ser_bill_nonbill")!==null){
          //alert($("#ser_bill_nonbill").val());
          map.push({name:"ser_bill_nonbill",value:$("#ser_bill_nonbill").val()})
        }
      
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_itemwisereport_new";
        CallMapMethod_CBack(clsViewTruckOut,map,function(data,msg){
          //alert(data);
          _sumAmount=0;
          _sumNetAmount=0;
            if(data!=="0" && data!==""){
              //alert(data);
                let jsData=JSON.parse(data);
                let listData=jsData["DataList"];
                var strData="";
                //alert(JSON.stringify(jsData["OutStanding"]));
                let vNetAmount=0;
                for(let i=0;i<listData.length;i++){
                    // alert(listData[i]["paid_fee"]);
                    // break;
                 
                  vNetAmount+=parseFloat(listData[i]["Amount"]);
                }
                
                $(".outstanding_rpt").html(listData.length+" records found | Net Total Amt:<b>"+vNetAmount+"</b> |");
                clsViewTruckOut.setState({stListTransactions:listData,stOutStanding:jsData["OutStanding"]},()=>{
                  //window.getPagination("#tbData");
                  //clsStudentAdmission.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
 
      
  okSuccEditInv(data,smg)
  {
    if(data==="001"){
        alert("Record Saved Successfully");
        window.location.reload();
        }else{
          alert(smg);
        }
  } 


      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
 this.clsBtnFeeBreakups =new listData("_btnFeeBreakdown", "hellow");
 this.clsBtnFeeBreakups.setOnChangeEvent("fee_breakdown",this.clsBtnFeeBreakups);
 this.clsBtnFeeBreakups.formFormuals=[{"cal_fields":"quantity,rate","calc_sign":"*","output":"amount"},
  {"cal_fields":"bags,quantity","calc_sign":"*","output":"package"},
  {"cal_fields":"amount,package","calc_sign":"+","output":"net_amount"}
];

 this.clsBtnFeeBreakups.editLoadDocPopup=function setEditLoadDocPopup(ee,index){
  //alert("i am in native click == "+index);
  clsViewTruckOut.clsBtnFeeBreakups.editCompleteCBack="divAddTransItem";
  clsViewTruckOut.setState({stEditPopupItem:clsViewTruckOut.clsBtnFeeBreakups.purchaseItem.tt[index]});
  clsViewTruckOut.clsBtnFeeBreakups.editIndex=index;
  $("#divEditTruckOut").modal('hide');
  $("#divAddTransItem").modal('show');
}

 this.clsBtnPaymentBreadownClick=new listData("_btnPaymentBreakdown","hellow");
this.getTruckProWise();


$('#divAddTransItem').on('hidden.bs.modal', function () {
  clsViewTruckOut.setState({stEditPopupItem:{"nn":""}},()=>{
    clsViewTruckOut.clsBtnFeeBreakups.editIndex=-1;
    $("#divEditTruckOut").modal('show');
  });

//  $("#divEditTruckOut").modal('show');
  
});
$("#divEditTruckOut").on('hidden.bs.modal',function(){
  //clsViewTruckOut.setState({stEditInvoice:[]});
})
//this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
    <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        Search
      </button>
      <button class="btn btn-sm btn-danger ml-2"  type="button" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>
      <Link className="btn btn-sm btn-danger float-right" to={"../ledger/truck_out"}>{"Add New TruckOut"}</Link>
    </div>
  </div>

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title"><i class="fa fa-arrow-circle-right"></i> <b>View TruckOut Challan(Product Wise)</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Receipt No.</th>
                      <th>Customer Name</th>
                      <th>Vehicle No</th>
                      <th>Site</th>
                      <th>RST No</th>
                      <th>Product</th>
                      <th>Unit</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Royalty No</th>
                      <th>Royalty Rate</th>
                      <th>Royalty Amt.</th>
                      <th>Net Amount</th>
                      <th>Trans. Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                    //alert(item["inv_process_status"]);
                    if(index==0){
                      _sumAmount=0;
                      _sumNetAmount=0;
                    }
                    _sumAmount+=parseFloat(item["Sub Total"]);
                      _sumNetAmount+=parseFloat(item["Amount"]);
                   
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["Date"]}</td>
                        <td>{item["Invoice No"]}</td>
                        <td>{item["Company Name"]}</td>
                        <td>{item["exp"]}</td>
                        <td>{item["site_name"]}</td>
                        <td>{item["col1"]}</td>
                        <td>{item["Item"]}</td>  
                        <td>{item["Item Unit"]}</td>
                        <td>{item["Quantity"]}</td>
                        <td>Rs.{item["Rate"]}</td>
                        <td>Rs.{item["Sub Total"]}</td>
                        <td>{item["col2"]}</td>
                        <td>{item["Bags"]}</td>
                        <td>{item["Pack"]}</td>
                        <td>Rs.{item["Amount"]}</td>
                        <td>{item["payment_mode"]}/{item["inv_process_status"]}</td> 
                    
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {item["inv_process_status"]!=="billed" && typeof _objAccess.can_edit!=="undefined"?<a class="dropdown-item" onClick={()=>this.getEditInvoice(item)} data-bs-toggle="modal" data-bs-target="#divEditTruckOut" data-toggle="modal" data-target="#divEditTruckOut" href="javascript:void(0)">View/Edit</a>:''}
                    {item["inv_process_status"]!=="billed" && typeof _objAccess.can_delete!=="undefined"?<a class="dropdown-item" onClick={()=>this.deletePurchaseItem(item)} href="javascript:void(0)">Delete</a>:''}
                    {typeof _objAccess.access_log!=="undefined"?<a class="dropdown-item" onClick={()=>this.setLogState(item)} data-bs-toggle="modal" data-bs-target="#divViewEntryLogs" data-toggle="modal" data-target="#divViewEntryLogs" href="javascript:void(0)">View Logs</a>:''}
                    
                    
                    {/*<a class="dropdown-item" onClick={()=>this.getEditInvoice(item)} data-bs-toggle="modal" data-bs-target="#divEditTruckOut"  data-toggle="modal"  data-target="#divEditTruckOut" href="javascript:void(0)">Print</a>*/}
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                  <tr>
                    <td colspan={"11"}></td>
                    <td>Rs.<b>{_sumAmount}</b></td>
                    <td colspan={"3"}></td>
                    <td>Rs.<b>{_sumNetAmount}</b></td>
                  </tr>
                  </tbody>
                </table>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr>
                      <td><b>Product</b></td>
                      <td><b>Unit</b></td>
                      <td><b>Net Quantity</b></td>
                      <td><b>Net Amount</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.stOutStanding.map((item,index)=>{
                      return <tr>
                        <td>{item["Item"]}</td>
                        <td>{item["Item Unit"]}</td>
                        <td>{item["Quantity"]}</td>
                        {/* <td>{item["Sub Total"]}</td> */}
                        <td>{item["Amount"]}</td>
                      </tr>
                    })}
                  </tbody>


                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_View_Ent_Logs logUser={userObject} logState={this.state.stLogState} />

<Comp_Add_Trans_Item logUser={userObject} editPopup={this.state.stEditPopupItem}/>

<Comp_Print_Report ptrColumnHeader={["Date","Receipt No.","Customer Name","Vehicle No","Site","RST No","Product","Unit","Qty",
  "Rate","Amt","Roy.No","Roy.Rate","Roy.Amt","Net Amt","Status"
]} 
  ptrColumnName={["Date","Invoice No","Company Name","exp","site_name","col1","Item","Item Unit","Quantity","Rate","Sub Total","col2",
    "Bags","Pack","Amount","payment_mode"
  ]}
  totals={[{"col":"Sub Total","col_index":11,"total_value":0},
    {"col":"Pack","col_index":14,"total_value":0},
    {"col":"Amount","col_index":15,"total_value":0}
  ]}

  
  ptrOutStandingCols={{"Item":"Product","Item Unit":"Unit","Quantity":"Net Quantity","Amount":"Net Amount"}}
  lsOutStandingData={this.state.stOutStanding}
  ptrOutStandingTitle={"Product/Unit Wise Outstanding"}
  
  lsTabelData={this.state.stListTransactions}
  ptrTitle={"Truck Out"}
  pageType="1"
   logUser={userObject}/>

<Comp_Edit_Truck_Out lsInvoice={this.state.stEditInvoice} lsPurchaseProduct={this.state.stEditProduct}
 lsPayment={this.state.stEditPayment} editUser={""}
 logUser={userObject} 
 clsParentListData={this.clsBtnFeeBreakups}
 clsParentPaymentButton={this.clsBtnPaymentBreadownClick}
 />

<Comp_Search_Pan onSearchFunc={this.getTruckProWise}
searchTitle={"Search TruckOut"}
searchOptions={{"company_name":"true",
// "inventory_ledger":"true",
"item":"true",
"site_name":"true",
"vehicle_no":"true",
"payment_mode":"true",
"bill_nonbill":"true",
"from_to_date":"true"}}
/>
          </div>)
      }
}
export default View_Truck_Out;