import $ from 'jquery';
import React from 'react';
import UserToken,{checkUserSession} from '../function_lib/userToken';
import {AjaxCallMethod,getAccessRolesNew,RespBkMthdMapp,_getInitParam,defaultLogo
,CallMapMethod_CBack} from '../function_lib/common_lib';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
//import {CallCMSMapMethod,AjaxCallMethodCMS} from '../function_lib/common_cms';
import {Add_Network_Comp} from '../components/add_network_comp';

import { Player } from 'video-react';

import GooglePlay from '../assets/images/google-play.jpg';
import AppStore from '../assets/images/mac.jpg';
import './index.css';


let loginUser="";
let userObject="";
let _indInitObject={};
let LogoImg;
let clsIndexLogin;
class Login extends React.Component{
  constructor(props){
    super(props);
    _indInitObject=_getInitParam();
    checkUserSession(false);//for limittime
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      userObject= UserToken.getUserObject();
      loginUser=userObject[0].person_name;
      window.location.href="./Dashboard";
    }
    LogoImg=defaultLogo();
    clsIndexLogin=this;
  }
  checkNetwork(){
    let map=[];
        map.push({name:"curl_type",value:"getNetworkd"});
        map.push({name:"res_func",value:"callBackrrr"});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getNetworkd";
        CallMapMethod_CBack(this,map,function(data,msg){
          let lsNetW=JSON.parse(data);
          if(lsNetW.length===0){
            $("#divNetWorkComp").modal('show');
          }

        })
  }

  checkLogin(){
    

    let user_captcha = document.getElementById('user_captcha').value;
// captcha validation
    if (validateCaptcha(user_captcha)===true) {
    let map=[];
    map.push({name:"curl_type",value:"logint"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"p1",value:$("#p1").val()});
    map.push({name:"p2",value:$("#p2").val()});
    map["res_func"]="callBackrrr";
    map["curl_type"]="logint";
    CallMapMethod_CBack(clsIndexLogin,map,function(data,msg){
      
      if(data!=="0" && data!==""){
        //alert(data);
        let jsData=JSON.parse(data);
        //alert(jsData.user_info.length);
        if(jsData.user_info.length===1){
          let datat={};
          datat["user"]=[{"id":jsData.user_info[0]["id"],
            "app_user_id":jsData.user_info[0]["id"],
            "company_name":jsData.user_info[0]["entity_name"],
            "first_name":jsData.user_info[0]["person_name"],
            "last_name":"",
            "type":"1",
            "access_type":jsData.user_info[0]["access_type"],
          }];
          //alert(jsData.user_info[0]["authority"]);
          //datat["role"]=[{"col1":"user,master"},{"col2":btoa(jsData.user_info[0]["access_location"])}];
            datat["role"]=[{"col1":btoa(jsData.user_info[0]["authority"])},{"col2":btoa(jsData.user_info[0]["access_modules"])}];
          
          clsIndexLogin.okSucc(JSON.stringify(datat),"success");
        }else{
          clsIndexLogin.okSucc("0","");
        }
      }
      
    })
  }else{
    clsIndexLogin.okSucc("0","Invalid Code");
  }


      
  


//     if($("#p1").val()==="admin" && $("#p2").val()==="1001"){
//       let datat={};
//       datat["user"]=[{"id":"nBwJY07WZF7E0ew-8Pd0966rdtKfpKPorGMq2OOrZqQG0LimGvQzs2K27TOOnPHB3hvoha5jqygNtyGMeUYycw",
//       "app_user_id":"nBwJY07WZF7E0ew-8Pd0966rdtKfpKPorGMq2OOrZqQG0LimGvQzs2K27TOOnPHB3hvoha5jqygNtyGMeUYycw",
//     "first_name":"Admin",
//   "last_name":"Admin",
// "type":"1"}];
// datat["role"]=[{"col1":"user,master"}];
//       this.okSucc(JSON.stringify(datat),"success");
//     }else{
//       this.okSucc("0","");
//     }
  }

  okSucc(data,datares){
    //alert(data+datares);
   // return;
   if(data===-10){
    return;
   }
    if(data!="0" && data!=""){
      $("#getReponse").html("Login Successfully..");
     checkUserSession(true);
     localStorage.setItem('userData',data);
     
     window.location.href="./dashboard";
     
     
    }else{
      if(datares!==""){
        $(".respSSL").html(datares);
      }else{
        $(".respSSL").html("Invalid UserName or Password !");
      }
      
    }

}

okFirstSetupResp(data,msg){
alert(data+msg)
}
componentDidMount(){
  loadCaptchaEnginge(4,'white','black','lower');
  AjaxCallMethod(this,"okSuccCNOT");
  this.checkNetwork();
  //RespBkMthdMapp(this,"","keyEvent");
}
    render(){
        return(<div>
          <div className="area" >
                      <ul className="circles">
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                              <li></li>
                      </ul>
              </div >
            
            <div className="container-fluid d-set ggg" >
            <div className="row">
              
              
              <div className="col-lg-4 col-md-4 col-11 m-auto d-block">
              <div className="card shadow-lg">
                <div className="card-header">
                  
                  <h3 className="text-center"><img src={_indInitObject["logoPath"].trim()!==""?_indInitObject["logoPath"]:LogoImg} style={{/*"background-color":"#000"*/}} className="img-fluid"  /></h3>
                  <div className="border-bottom"></div>
                <h6 className="text-center">
                  {/* ({_indInitObject.app_settings.client}) */}
                 <b> MUI e-Library</b>
                  <br/><span className="material-icons text-orange md-18 pt-1 font-small fgg">
          verified
          </span> For MUI, CMMI & IME(I) Members </h6>
                
                </div>
                          <div className="card-body">
                          <form id="frmLogin" name="frmLogin" action="#">
                          <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                    <input type="hidden" name="curl_type" id="curl_type" value="logint" />
                    <input type="hidden" name="m_type" id="m_type" value="logint" />
                    {/* <div className="form-group">
                             
                              <input type="text" className="form-control" id="txtCompanyCode" name="txtCompanyCode" placeholder="Company Code.." />
                            </div> */}
                            <div className="form-group">
                              <input type="text" className="form-control required" id="p1" name="p1" placeholder="Member-Id" />
                            </div>
                            <div className="form-group">
                              <input type="password" className="form-control required keyEvent_btn" rf="#btnLogin" id="p2" name="p2" placeholder="Password.." />
                            </div>
                            <div className="form-group">
                            <LoadCanvasTemplate />
                            <input id="user_captcha" name="user_captcha" type="text" class="form-control required" />

                            </div>
                            <div class="col-12 respSSL"></div>
                            <div className="form-group">
                  <button type="button" id="btnLogin" name="btnLogin" onClick={()=>this.checkLogin()} className="btn _btnSetColor123 btn-sm float-left btn-success">Submit</button>   <a className="font-small float-right float-right text-cyan" href="" >Forgot Password ?</a>
                            </div>
                            </form>
                </div>
                
                       <div className="border-bottom w-100"></div>
                {/* <h6 className="mt-3 ml-4 text-orange"	>Download App Now</h6> */}
                {/* <div className="row p-1">
                  
                  
                 <div className="col-lg-6 col-md-6 col-12">
                  <h1 className="text-center"><img src={GooglePlay} className="img-fluid" width="150" /></h1>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <h1 className="text-center"><img src={AppStore} className="img-fluid" width="150" /></h1>
                </div> 
                </div>
                 */}
                
              
                
                
                </div>
              
              
              
              
              </div>
              
              
              
              </div>	
            </div>
            <Add_Network_Comp  />
              </div>);
    }
}
export default Login