import $ from 'jquery';
import React from 'react';
import Chart from "react-google-charts";
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod, GetDateDifferecec,CallMapMethod_CBack} from '../function_lib/common_lib';
// import {GlobalContext} from '../context';
import {GbSetGetPartyInfo,GbSetGetItemInfo} from '../cGlobal/cls_global';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';

import LogoImg from '../assets/images/logo.png';
import defaultPic from '../assets/images/9.png';

//import Upcoming_Events from '../components/upcoming_events';
//import Emp_On_Leave from '../components/emp_on_leave';
//import Broadcast from '../components/broadcast';
//import Mobile_Widgets from '../components/mobile_widgets';
import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import {Comp_Quick_Activity} from '../lms_component/comp_quick_activity';
import {Comp_User_Activity} from '../lms_component/comp_user_activity';
import {Comp_Entity_Contents} from '../lms_component/comp_entity_contents';

import './dashboard_new.css';

//import UserManual from '../assets/Operational_Instructions.pdf';

let loginUser="";
let userObject="";
let companyName="";
let userType="";
let clsDashboardNew;

let _chkOrderCall=false;
let _chkAnalyticsCall=false;
let _chkCmsCall=false;
class Comp_Dashboard extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        //alert(JSON.stringify(this.props));
        clsDashboardNew=this;
        this.state={stGbPartyInfo:[],stDashboardData:{}};
        
        //this.state={no_entities:0,no_clients:0,no_vendors:0}
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        userType="0";
        
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }

     
      //alert(ret);
     
    }

    
     
    getLicenseInfo(){
      var map=[];
      map.push({name:"curl_type",value:"getLicense"});
      map.push({name:"res_func",value:"callBackrrr"});
      map.push({name:"local_token",value:userObject[0].app_user_id});
      //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
      
      map["res_func"]="callBackrrr";
      map["curl_type"]="getLicense";
      CallMapMethod_CBack(clsDashboardNew,map,function(data,msg){
        //alert(data);
          if(data==="001" && msg==="True"){
              
            }else{
              //alert("check");
              Custom_Alert("Not Valid",msg,true,"../");
              localStorage.clear();
            }
      });
}

getDashboardData(){
  let map=[];
  map.push({name:"curl_type",value:"getDashboardAnalytics"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  //map.push({name:"acc_inf_id",value:userObject[0].app_user_id});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getDashboardAnalytics";
  CallMapMethod_CBack(clsDashboardNew,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      clsDashboardNew.setState({stDashboardData:lsData});
    }
  });

}

      componentDidMount(){
        this.getLicenseInfo();
        this.getDashboardData();
        //this.getMasters();
        $("#divEmployee").show();
        //this.forceUpdateHandler();
      }
   //Get no of Entities/Client/Vendos
     
   
     

    render(){
      
        return(<div className="wrapper">
            <Header/>
            {/* <GbSetGetPartyInfo getSet="set" lsPartyInfo={this.state.stGbPartyInfo}/> */}
            {/* <Mobile_Widgets/> */}
            <div class="content" style={{"min-height":"150px"}}>
            <div class="container-fluid mt-4">

              
         
        
                  {/* component init  */}
                    {/* entity component */}
                <Comp_Entity_Contents dashboardData={this.state.stDashboardData}/>
                {/* end of entity */}
                  

                    {/* quick activivity */}
                    <Comp_Quick_Activity/>
                    {/* end of quick acitivity */}

                    {/* User Activity */}
                    <Comp_User_Activity/>
                    {/* end of user Activity */}

                

                  {/* end of component init */}
             
</div>
      </div> 
      <Alert_Content/>
        <Footer/>
        
      </div>)
    }
}
export default Comp_Dashboard