import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
// import {Comp_Add_Product_Master} from '../components/comp_add_product_master';
// import {CompItem_Auto_Complete_New} from '../components/comp_auto_complete';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';

//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';
//import Comp_Read_Study_Material from '../lms_component/comp_read_study_material';
import Comp_View_Pdf from '../lms_component/comp_view_pdf';
import Comp_Bhandarkar_Book_Card from '../lms_component/comp_bhandarkar_book_card';
import Comp_Bhandarkar_Sidebar_Menu from '../lms_component/comp_bhandarkar_sidebar_menu';

let loginUser="";
let userObject="";
let companyName="";
let clsProductMaster;

let rf="0";
let _objAccess={};
let _serFilters={ser_books_categories:""};
class View_Study_Material_New extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={ listUsers:[],stEditProduct:{},
                  stListCategories:[],stListItemCategory:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsProductMaster=this;
      }

      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getCategoriesMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_text",value:$("#ser_text").val()});
       
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCategoriesMaster";
        CallMapMethod_CBack(clsProductMaster,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsProductMaster.setState({stListCategories:listData},()=>{
                //   window.getPagination("#tbData");
                clsProductMaster.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                 
                  if(rf!=="0"){
                    clsProductMaster.getSearch(rf);
                    //alert("i m in setup "+rf);
                    $("#serLoanStatus").val(rf);
                  }
                });
              }
        });
      }

      setSearchPramasGb(serFilters){
        _serFilters=serFilters;
        clsProductMaster.getStudyMasterial();
      }

      getStudyMasterial(){
        //alert($("#ser_text").val());
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getStudyMasterial"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        //map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        
        map.push({name:"ser_params",value:JSON.stringify(_serFilters)});
        
        //alert(JSON.stringify(serFilters));
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getStudyMasterial";
        CallMapMethod_CBack(clsProductMaster,map,function(data,msg){
          
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                clsProductMaster.setState({listUsers:listData},()=>{
                  //window.getPagination("#tbData");
                  clsProductMaster.forceUpdateHandler();
              
                });
              }
        });
      }
      okRespProductAdd(data,msg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful",msg,true);
          //clsProductMaster.getProductMaster();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",msg,false);
        }
        // alert(data);
      }


 setEditProductMaster(item){
  clsProductMaster.setState({stEditProduct:item});
 }
 onViewPdf(data){
  //alert(JSON.stringify(data));
  clsProductMaster.setState({stItemProduct:data});
}              
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getCategories();
this.getStudyMasterial();

$('#divFeeCategory').on('hidden.bs.modal', function () {

  clsProductMaster.getStudyMasterial();
 
});
}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  {/* <CompItem_Auto_Complete_New setData={true} autoList={this.state.listUsers}/> */}
<div className="row content12 mt-2 mb-2">

<div class="col-6"></div>

<div className='col-lg-4 col-md-4 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='search by book title,author,publisher' />
             </div>
         
         </div>

</div>

<div className="col-lg-2 col-md-2 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               <div className='col-12 '>
                 <div onClick={()=>this.getStudyMasterial()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()}  className="btn btn-sm btn-danger">Reset</div>
                 </div>
               </div>
             </div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Publication/Pappers</b></h3>
                
                <a className="btn btn-sm btn-danger float-right" href="../addstudymaterial">Add New</a>
              </div>
              <div class="container111 py-3">
        <div class="row justify-content-center">
            <div class="col-3">
                <Comp_Bhandarkar_Sidebar_Menu runSearch={this.setSearchPramasGb} listCategories={this.state.stListCategories}/>
                </div>
          <div class="col-9 book-detail-card">
              <Comp_Bhandarkar_Book_Card onViewBook={this.onViewPdf} bookList={this.state.listUsers} />
              </div>
              </div>
              </div>
                
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
{/* <Alert_Content/> */}
<Footer/>
<Comp_View_Pdf proItem={this.state.stItemProduct} />
{/* <Comp_Read_Study_Material pdfSource={"https://lmstestd.bhandarkarpublications.com/dtup/A1.1.pdf"}/> */}
{/* <Comp_Add_Product_Master editProduct={this.state.stEditProduct} logUser={userObject} lsUnit={this.state.stListUnit} lsProCategory={this.state.stListItemCategory}/> */}

    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default View_Study_Material_New;